<template>
    <div class="exactCenter">
        <div>
            <img class="img0" src="../assets/Combined_Logo.png">
        </div>
        <div>
            <a href="https://proderivatives.com">
                <img class="img1" src="../assets/ProDerivatives.png">
            </a>
        </div>
    </div>
</template>

<style scoped>
.exactCenter {
    text-align: center;
}
.img0 {
  margin: 10%;
  width: 50%;
}
.img1 {
  margin: 0%;
  width: 30%;
}
</style>

<script>
export default {
  name: 'PortfolioView',
}
</script>