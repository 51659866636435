<template>
  <div id="app">
    <transition name="slide-fade">
      <div @click="transition" v-if="showLanding">
        <Landing />
      </div>
    </transition>
    <transition name="slide-fade">
      <div v-if="showPortfolio">
        <Portfolio />
      </div>
    </transition>
  </div>
</template>

<style scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>

<script>
import Landing from './components/LandingPage.vue'
import Portfolio from './components/PortfolioView.vue'

export default {
  name: 'App',
  components: {
    Landing,
    Portfolio
  },
  data: () => ({
            showLanding: true,
            showPortfolio: false
        }) ,
  methods: {
    sleep (ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async transition () {
      this.showLanding = false
      await this.sleep(800)
      this.showPortfolio = true
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
