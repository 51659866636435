<template>
  <div class="exactCenter">
    <img src="../assets/logo.png">
  </div>
</template>

<style scoped>
.exactCenter {
    text-align: center;}
img {
  margin: 5%;
  width: 30%;
}
</style>

<script>
export default {
  name: 'LandingPage',
}
</script>


